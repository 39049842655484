import { createRouter, createWebHistory } from "vue-router";
import homePage from "../views/homePage.vue";
import aboutPage from "../views/aboutPage.vue";
import productPage from "../views/productPage.vue";
import servicePage from "../views/servicePage.vue";
import contactPage from "../views/contactPage.vue";
import clientPage from "../views/clientPage.vue";
import careerPage from "../views/careerPage.vue";
import articlePage from "../views/articlePage.vue";
import articleDetailPage from "../views/articleDetailPage.vue";
import productDetailPage from "../views/productDetailPage.vue";
// import customPage from "../views/customPage.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: homePage,
  },
  {
    path: "/about",
    name: "about",
    component: aboutPage,
  },
  {
    path: "/products",
    name: "products",
    component: productPage,
  },
  {
    path: "/products/:id",
    name: "productDetail",
    component: productDetailPage,
  },
  {
    path: "/services",
    name: "services",
    component: servicePage,
  },
  {
    path: "/contact",
    name: "contact",
    component: contactPage,
  },
  {
    path: "/client",
    name: "client",
    component: clientPage,
  },
  {
    path: "/career",
    name: "career",
    component: careerPage,
  },
  {
    path: "/articles",
    name: "article",
    component: articlePage,
  },
  {
    path: "/article/:id",
    name: "articleDetail",
    component: articleDetailPage,
  },
  // {
  //   path: "/:id",
  //   name: "dynamic",
  //   component: customPage,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
