<template>
    <HeaderSite :parentMenu="this.$route.name" />
    <div class="bg-ligth-grey">
        <div class="container">
            <div class="row py-5 justify-content-md-center">
                <div class="col-5 text-center illus">
                    <img src="@/assets/img/illus1.jpg" class="col-md-12">
                </div>
                <div class="col-7 col-md-6 align-self-center service">
                    <div class="row row-cols-1 row-cols-md-2 g-4">
                        <div class="col">
                            <div class="card">
                                <div class="card-body text-center">
                                    <div class="my-4"></div>
                                    <div class="fw-bolder mx-3 fs-3">Show All Services</div>
                                    <hr class="mx-5 my-4">
                                    <div class="my-4 d-grid col-10 mx-auto"><button type="button" class="btn btn-primary fs-5 py-3">Open</button></div>
                                    <div class="my-5"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col" v-for="service in itemServices" :key="service.id">
                            <div class="card">
                                <div class="card-body text-center">
                                    <div class="my-4"></div>
                                    <div class="w-100"><img :src="require(`@/assets/img/${service.icon}`)" /></div>
                                    <div class="w-100 fw-bolder my-3">{{service.name}}</div>
                                    <hr class="mx-5">
                                    <div class="service-desc my-4">{{service.desc.substring(0, 134)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-md-5 text-center">
                    <div class="my-5"></div>
                    <h2>Our Products and Projects</h2>
                    <div class="py-3">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-3 my-3 mx-3 px-0 prod d-grid justify-content-center btn-div">
                    <div class="position-absolute text-center col col-12">
                        <span class="material-icons">keyboard_double_arrow_right</span>
                        <div class="px-5"><h3>See All</h3></div>
                    </div>
                    <img :src="require(`@/assets/img/prod7.jpg`)" />
                </div>
                <div class="col-md-3 my-3 mx-3 px-0 prod d-grid justify-content-center btn-div" v-for="prod in itemProducts" :key="prod.id">
                    <div class="title"><h3>{{prod.name}}</h3></div>
                    <img :src="require(`@/assets/img/${prod.icon}`)" />
                </div>
            </div>
            <div class="my-5"></div>
        </div>
    </div>
    <div class="bg-ligth-grey">
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-md-5 text-center">
                    <div class="my-5"></div>
                    <h2>What Our Clients Say</h2>
                    <div class="my-5"></div>
                </div>
            </div>
            <div class="row justify-content-md-center">
                <div class="col-md-4 my-3" v-for="testi in itemTestimoni" :key="testi.id">
                    <div class="card">
                        <div class="card-body">
                            <div class="row my-3 g-0">
                                <div class="col-2 px-0 text-end">
                                    <img :src="require(`@/assets/img/${testi.icon}`)" />
                                </div>
                                <div class="col-1"></div>
                                <div class="col-9 px-0">
                                    <span class="fw-bolder">{{testi.name}}</span> <br>
                                    <span><small style="color:#6975b1">{{testi.title}}</small></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    {{testi.desc.substring(0, 134)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-md-center">
                <div class="col text-center my-5">
                    <button type="button" class="btn btn-primary py-3 px-5">See All</button>
                </div>
            </div>
        </div>
    </div>
    <div class="corpInfo">
        <div class="py-5"></div>
        <div class="container">
            <div class="row text-center px-3 justify-content-md-center">
                <div class="col-md-4 py-4">
                    <span class="material-icons fs-1 float-start">
                        location_on
                    </span>
                    <b>Main Office</b> <br>
                    {{corpInfo.address1}}
                </div>
                <div class="col-md-4 py-4">
                    <span class="material-icons fs-1 float-start">
                        location_on
                    </span>
                    <b>Operational Office</b> <br>
                    {{corpInfo.address2}}
                </div>
            </div>
            <div class="row text-center px-3 justify-content-md-center">
                <div class="col-md-4 py-4">
                    <span class="material-icons fs-1 align-middle">
                        email
                    </span>
                    {{corpInfo.email}}
                </div>
                <div class="col-md-4 py-4">
                    <span class="material-icons fs-1 align-middle">
                        phone_in_talk
                    </span>
                    {{corpInfo.telp}}
                </div>
            </div>
        </div>
        <div class="py-5"></div>
    </div>
    <FooterSite />
    
</template>

<script>
import HeaderSite from '@/components/headerSite.vue';
import FooterSite from '@/components/footerSite.vue';

export default {
    name : 'homePage',
    components : {
        HeaderSite,
        FooterSite
    },
    data(){
        return{
            itemServices : [],
            itemProducts : [],
            itemTestimoni : [],
            corpInfo : {},
            show : false
        }
    },
    mounted() {
        this.loadHome();
        this.loadInfoCorp();
    },
    methods : {
        loadHome : function(){

            this.axios
            .get(process.env.VUE_APP_SUBPATH+'data-sample/home.json')
            .then(response => {
                this.itemServices = response.data.itemServices;
                this.itemProducts = response.data.itemProducts;
                this.itemTestimoni = response.data.itemTestimoni;
            })

        },

        loadInfoCorp : function(){

            this.axios
            .get('data-sample/corp-info.json')
            .then(response => {
                this.corpInfo = response.data.corpInfo;
            })

        },

        showProdTitle(e){
            e.target.parentElement.children[0].classList.add('title-hover') ;
            e.target.parentElement.children[1].classList.add('box-shadow-blue') ;
        },

        hideProdTitle(e){
            e.target.parentElement.children[0].classList.remove('title-hover') ;
            e.target.parentElement.children[1].classList.remove('box-shadow-blue') ;
        }
    }
}
</script>

<style scoped>
.bg-ligth-grey{
    background-color: #d9d9d9;
    width: 100%;
}
.illus img{
    border: 3px solid #fff;
    border-radius: 10px;
    box-shadow: 0 0px 10px rgb(107, 107, 107) ;
}
.card{
    border-radius: 10px !important;
    min-height: 17em !important;
    min-width: 50% !important;
}
.card-body{
    border-radius: 10px !important;
    box-shadow: 0 0px 10px rgb(107, 107, 107) ;
    transition: .6s ease box-shadow;
}
.card-body:hover{
    box-shadow: 0 0px 15px rgb(86, 175, 248) !important;
}
.card-body img{
    height: 60px;
}
.card-body hr{
    border: none;
    height: 3px !important;
    /* Set the hr color */
    color: #333; /* old IE */
    background-color: #333; /* Modern Browsers */
}

.service-desc{
    font-size: 10pt;
}
.prod{
    position: relative;
}
.prod img, .prod button{
    width: 100%;
    background-color: #d9d9d9;
    border-radius: 10px;
    box-shadow: 0 0px 10px rgb(156, 156, 156) ;
    transition: .6s ease border, .6s ease box-shadow;
}

.prod .material-icons{
    font-size: 7.85em !important;
    color: #bbb;
}

.prod button{
    color: #efefef;
    border: none;
}

.title{
    position: absolute;
    width: 100%;
    height: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: right;
    font-weight: bolder;
    color: #fff;
    font-style: italic;
    background: linear-gradient(180deg, rgb(97, 97, 97) 0%, rgba(150, 150, 150, 0) 100%);
    transition: .6s ease;
    overflow: hidden;
}

.title h3{
    position: absolute;
    width: 100%;
    padding-right: 20px;
}
.prod:hover .title{
    height: 80px;
    padding-top: 20px;
}
.prod:hover, .prod button:hover{
    box-shadow: 0 0px 10px rgb(86, 175, 248) !important;
    border-radius: 10px;
}

.btn-secondary:hover{
    background-color: rgb(173, 173, 173) !important;
}

.corpInfo{
    background-image: url(@/assets/img/footer-banner.jpg);
    background-size: cover;
    background-position: center;
}

.btn-div:hover{
    cursor: pointer;
}

@media screen and (max-width: 1199px) {
    .illus{
        display: none;
    }
    .service{
        width: 100% !important;
    }
    .title{
        height: 80px;
        padding-top: 20px;
    }
}

@media screen and (max-width: 777px) {
    .prod .material-icons{
        margin-top: .8em;
    }
}

</style>