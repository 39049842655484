<template>
  <div class="corpInfo">
    <div class="py-5"></div>
    <div class="container">
      <div class="row text-left px-3 justify-content-md-center">
        <div class="col-md-4 py-4">
          <div class="row">
            <div class="col-2 col-sm-2 text-end">
              <span class="material-icons fs-1"> location_on </span>
            </div>
            <div class="col-10 col-sm-10">
              <p><b>Main Office</b></p>
              <div v-html="corpInfo.one"></div>
            </div>
          </div>
        </div>
        <div class="col-md-4 py-4">
          <div class="row">
            <div class="col-2 col-sm-2 text-end">
              <span class="material-icons fs-1"> location_on </span>
            </div>
            <div class="col-10 col-sm-10">
              <p><b>Operational Office</b></p>
              <div v-html="corpInfo.two"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row text-left px-3 justify-content-md-center">
        <div class="col-md-4 py-4">
          <div class="row">
            <div class="col-2 col-sm-2 text-end">
              <span class="material-icons fs-1"> email </span>
            </div>
            <div class="col-10 col-sm-10">
              <a :href="`mailto:{{corpInfo.email}}`"
                ><div v-html="corpInfo.email"></div
              ></a>
            </div>
          </div>
        </div>
        <div class="col-md-4 py-4">
          <div class="row">
            <div class="col-2 col-sm-2 text-end">
              <span class="material-icons fs-1"> phone_in_talk </span>
            </div>
            <div class="col-10 col-sm-10">
              <a :href="`tel:{{corpInfo.telp}}`"
                ><div v-html="corpInfo.telp"></div
              ></a>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row-reverse">
        <ul id="services">
          <li>
            <div class="facebook">
              <a :href="fblink" target="_blank">
                <i class="fa fa-facebook" aria-hidden="true"></i>
              </a>
            </div>
          </li>
          <li>
            <div class="instagram">
              <a :href="iglink" target="_blank">
                <i class="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </div>
          </li>
          <li>
            <div class="linkedin">
              <a :href="inlink" target="_blank">
                <i class="fa fa-linkedin" aria-hidden="true"></i>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="py-5"></div>
  </div>
  <div class="footer">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-md-4 py-3 text-center">
          Copyright &copy; 2023 | &nbsp;
          <img :src="require(`@/assets/img/ams.png`)" width="70" />
        </div>
      </div>
    </div>
  </div>
  <div id="scroll-top">
    <button type="button" class="btn btn-danger btn-sm btn-scroll-top">
      <span class="material-icons"> arrow_upward </span>
    </button>
  </div>
</template>
<script>
import { db } from "@/main";
import { doc, getDoc } from "firebase/firestore";

export default {
  name: "footerSite",
  props: {
    activeMenu: { type: String, default: "home" },
    parentMenu: String,
  },
  data() {
    return {
      corpInfo: {
        one: "",
        two: "",
        telp: "",
        email: "",
      },
      fblink: "",
      iglink: "",
      inlink: "",
    };
  },
  mounted() {
    this.loadCorpInfoFirebase1();
    this.loadCorpInfoFirebase2();
    this.scrollFunction();
    window.addEventListener("scroll", this.scrollFunction);
    document
      .getElementById("scroll-top")
      .querySelector(".btn-scroll-top")
      .addEventListener("click", this.scrollToTop);
  },
  unmounted() {
    window.removeEventListener("scroll", this.scrollFunction);
  },
  methods: {
    scrollFunction: function () {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        document.getElementById("scroll-top").classList.add("active");
      } else {
        document.getElementById("scroll-top").classList.remove("active");
      }
    },
    scrollToTop: function () {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    loadCorpInfoFirebase1: async function () {
      try {
        const docRef = doc(db, "corpInfo", "ams");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          this.corpInfo.one = data.address1;
          this.corpInfo.two = data.address2;
          this.corpInfo.telp = data.telp;
          this.corpInfo.email = data.email;
        }
      } catch (error) {
        console.error(error);
      }
    },
    loadCorpInfoFirebase2: async function () {
      try {
        const docRef = doc(db, "corpInfo", "socialMedia");
        const snap = await getDoc(docRef);

        if (snap.exists()) {
          const data = snap.data();
          this.fblink = data.facebook;
          this.iglink = data.instagram;
          this.inlink = data.linkedin;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // emitEventChanged() {
    //   this.$emit("GetCorpInfo", this.corpInfo);
    // },
  },
};
</script>
<style>
#scroll-top {
  position: fixed;
  bottom: 10px;
  right: 20px;
  opacity: 0;
  transition: opacity 1s;
}
#scroll-top.active {
  opacity: 1;
  transition: opacity 1s;
}

.corpInfo {
  background-image: url(@/assets/img/footer-banner1.jpg);
  background-size: cover;
  background-position: center;
}

.corpInfo a {
  text-decoration: none;
  color: black;
}
.corpInfo .row .row:hover,
.corpInfo a:hover {
  color: #dc3545;
  transition: 1s ease;
}

#services {
  text-align: center;
  transform: translatez(0);
}
#services li {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin: 5px;
  list-style: none;
}
#services li div {
  font-size: 1em;
  text-align: center;
  line-height: 40px;
  background-color: #640303;
  border-radius: 3px;
  transition: all 0.5s ease;
}
#services li a {
  color: #fff1f1;
}
#services li div:hover {
  transform: rotate(360deg);
  border-radius: 100px;
}

@media screen and (max-width: 650px) {
  .powered-by {
    position: relative;
    margin-bottom: 20px;
    top: 0;
    left: 0;
    text-align: center;
  }
}
</style>
