<template>
  <HeaderSite :parentMenu="this.$route.name" />
  <div class="container article">
    <hr />
    <div class="mx-3">
      <router-link :to="'/articles'" class="btn btn-secondary" role="button"
        ><svg
          height="25px"
          id="Layer_1"
          style="enable-background: new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          width="25px"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <polygon
            points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256"
            fill="white"
          /></svg
      ></router-link>
    </div>
    <div class="row justify-content-md-center my-3 px-3">
      <h6 class="display-4">{{ article.title }}</h6>
      <p>{{ formatUpdatedAt(article.updatedAt) }}</p>
      <div v-html="article.content"></div>
    </div>
    <hr />
    <div class="mx-3 my-3">
      <router-link :to="'/articles'" class="btn btn-secondary" role="button"
        ><svg
          height="25px"
          id="Layer_1"
          style="enable-background: new 0 0 512 512"
          version="1.1"
          viewBox="0 0 512 512"
          width="25px"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <polygon
            points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256"
            fill="white"
          /></svg
      ></router-link>
    </div>
  </div>
  <FooterSite />
</template>

<script>
import { db } from "@/main";
import FooterSite from "@/components/footerSite.vue";
import HeaderSite from "@/components/headerSite.vue";
import { getDoc, doc } from "firebase/firestore";

export default {
  name: "articleDetailPage",
  components: {
    HeaderSite,
    FooterSite,
  },
  data() {
    return {
      article: {},
    };
  },
  mounted() {
    this.loadArticle();
  },
  methods: {
    convertTimestamp(seconds, nanoseconds) {
      return new Date(seconds * 1000 + nanoseconds / 1000000);
    },
    formatUpdatedAt(updatedAt) {
      if (updatedAt && updatedAt.seconds) {
        const date = this.convertTimestamp(updatedAt.seconds, updatedAt.nanoseconds);
        return date.toLocaleString();
      }
      return "";
    },
    loadArticle: async function () {
      try {
        const singleArticle = doc(db, "article", this.$route.params.id);
        const getArticle = await getDoc(singleArticle);
        this.article = getArticle.data();
      } catch (error) {
        console.error("Error fetching one article: ", error);
      }
    },
  },
};
</script>
