<template>
  <HeaderSite :parentMenu="this.$route.name" />
  <div class="container">
    <div class="row justify-content-md-center pt-4">
      <div
        class="my-2 title-article"
        v-for="article in articles"
        :key="article.id"
      >
        <h5>{{ article.title }}</h5>
        <img
          class="my-2 shadow-sm"
          :src="'data:image/png;base64,' + article.image"
          width="320"
          height="240"
        />
        <p>{{ formatUpdatedAt(article.updatedAt) }}</p>
        <p>{{ article.shortDescription }}</p>
        <div>
          <router-link
            class="text-decoration-none"
            :to="'/article/' + article.id"
            >Read More...</router-link
          >
        </div>
        <hr />
      </div>
    </div>
    <div v-if="hideMoreButton" class="text-center">
      <button
        v-if="!disableMoreButton"
        class="btn btn-danger btn-sm m-4"
        @click="next"
      >
        More
      </button>
    </div>
  </div>
  <FooterSite />
</template>

<script>
import { db } from "@/main";
import FooterSite from "@/components/footerSite.vue";
import HeaderSite from "@/components/headerSite.vue";
import {
  getDocs,
  collection,
  limit,
  query,
  getCountFromServer,
  startAfter,
  orderBy,
} from "firebase/firestore";

export default {
  name: "articlePage",
  components: {
    HeaderSite,
    FooterSite,
  },
  data() {
    return {
      articles: [],
      lastVisible: 0,
      documentCount: 0,
      hasMore: true,
    };
  },
  computed: {
    disableMoreButton() {
      return this.documentCount <= 3;
    },
    hideMoreButton() {
      return this.hasMore;
    },
  },
  mounted() {
    this.loadCount();
    this.loadArticle();
  },
  methods: {
    convertTimestamp(seconds, nanoseconds) {
      return new Date(seconds * 1000 + nanoseconds / 1000000);
    },
    formatUpdatedAt(updatedAt) {
      if (updatedAt && updatedAt.seconds) {
        const date = this.convertTimestamp(updatedAt.seconds, updatedAt.nanoseconds);
        return date.toLocaleString();
      }
      return "";
    },
    loadCount: async function () {
      try {
        const coll = collection(db, "article");
        const snapshot = await getCountFromServer(coll);
        this.documentCount = snapshot.data().count;
      } catch (error) {
        console.error("Error getting documents: ", error);
      }
    },
    loadArticle: async function () {
      try {
        const getQuery = await getDocs(
          query(
            collection(db, "article"),
            orderBy("updatedAt", "desc"),
            limit(3)
          )
        );
        const articles = [];
        const lastVisible = getQuery.docs[getQuery.docs.length - 1];
        this.lastVisible = lastVisible;
        getQuery.forEach((doc) => {
          articles.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        this.articles = articles;
      } catch (error) {
        console.error("Error loading articles: ", error);
      }
    },
    next: async function () {
      try {
        const nextPage = query(
          collection(db, "article"),
          orderBy("updatedAt", "desc"),
          startAfter(this.lastVisible),
          limit(3)
        );

        const querySnapshot = await getDocs(nextPage);

        if (querySnapshot.docs.length > 0) {
          const articles = [];
          const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          this.lastVisible = lastVisible;

          querySnapshot.forEach((doc) => {
            articles.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          this.articles = [...this.articles, ...articles];
        } else {
          this.hasMore = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
