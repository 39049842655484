<template>
  <HeaderSite :parentMenu="this.$route.name" />

  <div>
    <div class="container">
      <div class="row row-cols-1 row-cols-md-3 g-4 my-3">
        <div class="col" v-for="prod in itemProducts" :key="prod.id">
          <div class="card h-100">
            <img
              :src="'data:image/png;base64,' + prod.image"
              class="card-img-top"
              width="300"
              height=""
            />
            <div class="card-body text-justify">
              <h5 class="card-title">{{ prod.name }}</h5>
              <p class="card-text">{{ prod.shortDescription }}</p>
              <div class="row">
                <div class="col-md-12 text-center d-grid">
                  <!-- <button type="button" class="btn btn-outline-primary btn-sm" @click="$router.push({ name: 'productDetail', params: { id: prod.id } })">More Detail</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="hideMoreButton" class="text-center">
        <button
          v-if="!disableMoreButton"
          class="btn btn-danger btn-sm m-4"
          @click="next"
        >
          More
        </button>
      </div>
      <!-- <div class="d-flex justify-content-center paging mb-5">
        <div class="col-1 text-center m-3" v-if="currentPage > 1">
          <span class="material-icons fs-1"> chevron_left </span>
        </div>
        <div class="col-1 text-center m-3" v-if="currentPage < totalPage">
          <span class="material-icons fs-1"> chevron_right </span>
        </div>
      </div> -->
    </div>
  </div>

  <FooterSite />
</template>

<script>
import HeaderSite from "@/components/headerSite.vue";
import FooterSite from "@/components/footerSite.vue";
import {
  collection,
  getCountFromServer,
  getDocs,
  query,
  startAfter,
  limit,
  orderBy,
} from "firebase/firestore";
import { db } from "@/main";

export default {
  name: "productPage",
  components: {
    HeaderSite,
    FooterSite,
  },
  data() {
    return {
      itemProducts: [],
      lastVisible: 0,
      documentCount: 0,
      hasMore: true,
      currentPage: 0,
      totalPage: 0,
    };
  },
  computed: {
    disableMoreButton() {
      return this.documentCount <= 3;
    },
    hideMoreButton() {
      return this.hasMore;
    },
  },
  mounted() {
    // this.loadProduct();
    this.loadCount();
    this.loadFirebaseProduct();
  },
  methods: {
    loadProduct: function () {
      this.axios
        .get(process.env.VUE_APP_SUBPATH + "data-sample/prods.json")
        .then((response) => {
          this.itemProducts = response.data.items;
          this.currentPage = response.data.currentpage;
          this.totalPage = response.data.totalpage;
        });
    },
    loadCount: async function () {
      try {
        const coll = collection(db, "product");
        const docsSnapshot = await getCountFromServer(coll);
        this.documentCount = docsSnapshot.data().count;
      } catch (error) {
        console.error(error);
      }
    },
    loadFirebaseProduct: async function () {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(db, "product"),
            orderBy("createdAt", "asc"),
            limit(3)
          )
        );
        const products = [];
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        this.lastVisible = lastVisible;
        querySnapshot.forEach((doc) => {
          products.push({
            ...doc.data(),
          });
        });
        this.itemProducts = products;
      } catch (error) {
        console.error(error);
      }
    },
    next: async function () {
      try {
        const nextPage = query(
          collection(db, "product"),
          orderBy("createdAt", "asc"),
          startAfter(this.lastVisible),
          limit(3)
        );

        const querySnapshot = await getDocs(nextPage);

        if (querySnapshot.docs.length > 0) {
          const products = [];
          const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          this.lastVisible = lastVisible;
          querySnapshot.forEach((doc) => {
            products.push({
              ...doc.data(),
            });
          });
          this.itemProducts = [...this.itemProducts, ...products];
        } else {
          this.hasMore = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.card {
  transition: 0.6s ease box-shadow;
}
.card:hover {
  box-shadow: 0 0px 15px #dc3545 !important;
  cursor: pointer;
}

.paging div {
  transition: 0.6s ease;
  border-radius: 3em;
}

.paging div:hover {
  background-color: #efefef;
  cursor: pointer;
}
</style>
