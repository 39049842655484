<template>
  <HeaderSite :parentMenu="this.$route.name" />
  <div class="bg-ligth-grey">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-md-5 text-center">
          <div class="my-5"></div>
          <h2>Please Ask Us</h2>
          <p>
            If you have any question, just fill in the contact form, and we will
            answer you shortly.
          </p>
          <div class="my-3"></div>
        </div>
      </div>
      <form action="">
        <div class="row g-2">
          <div class="col-md-4 form-floating mb-3">
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v$.form.name.$error }"
              id="floatName"
              placeholder="Your name"
              v-model="form.name"
            />
            <label for="floatName">Name</label>
            <div
              class="input-errors px-1"
              v-for="(error, index) of v$.form.name.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="col-md-4 form-floating mb-3">
            <input
              type="email"
              class="form-control"
              :class="{ 'is-invalid': v$.form.email.$error }"
              id="floatEmail"
              placeholder="Email"
              v-model="form.email"
            />
            <label for="floatEmail">Email</label>
            <div
              class="input-errors px-1"
              v-for="(error, index) of v$.form.email.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="col-md-4 form-floating mb-3">
            <input
              type="text"
              pattern="[0-9]*"
              @input="handlePhoneNumberInput"
              class="form-control"
              :class="{ 'is-invalid': v$.form.phoneNumber.$error }"
              id="floatMobile"
              placeholder="Mobile"
              v-model="form.phoneNumber"
            />
            <label for="floatMobile">Mobile</label>
            <div
              class="input-errors px-1"
              v-for="(error, index) of v$.form.phoneNumber.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
        <div class="row g-2">
          <div class="col form-floating mb-3">
            <textarea
              class="form-control"
              :class="{ 'is-invalid': v$.form.message.$error }"
              placeholder="Leave a message here"
              id="floatMessage"
              v-model="form.message"
              style="height: 200px"
            ></textarea>
            <label for="floatMessage">Message</label>
            <div
              class="input-errors px-1"
              v-for="(error, index) of v$.form.message.$errors"
              :key="index"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 mb-5">
            <vue-recaptcha
              v-show="showRecaptcha"
              sitekey="6Lc3XU0iAAAAAIEG5Ob4YEtMPV0RVKY5_yg-tYjC"
              theme="light"
              size="normal"
              @verify="recaptchaVerified"
              @expire="recaptchaExpired"
              @fail="recaptchaFailed"
              ref="VueRecaptcha"
            />
          </div>
          <div class="col-md-8 text-center d-grid">
            <button class="btn btn-primary btn-lg" @click.prevent="sendEmail()">
              Submit
            </button>
            <div class="my-4"></div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <Modal ref="modal" :modal="modal" />

  <FooterSite @GetCorpInfo="getCorpInfo" />
</template>

<script>
import HeaderSite from "@/components/headerSite.vue";
import FooterSite from "@/components/footerSite.vue";
import Modal from "@/components/modal.vue";
import VueRecaptcha from "vue3-recaptcha2";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore"
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { db } from "@/main";

export default {
  name: "contactPage",
  components: {
    HeaderSite,
    FooterSite,
    VueRecaptcha,
    Modal,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
        sentAt: firebase.firestore.Timestamp.now(),
      },
      resRecaptcha: null,
      modal: {
        title: null,
        body: null,
      },
      showRecaptcha: true,
      corpInfo: {},
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        phoneNumber: {
          required,
        },
        message: {
          required,
        },
        email: {
          required,
          email,
        },
      },
    };
  },
  mounted() {
    this.loadCorpInfo();
  },
  methods: {
    handlePhoneNumberInput(event) {
      const inputValue = event.target.value.replace(/\D/g, "");
      this.form.phoneNumber = inputValue;
    },
    recaptchaVerified(response) {
      this.resRecaptcha = response;
    },

    recaptchaExpired() {
      this.$refs.VueRecaptcha.reset();
    },

    loadCorpInfo: async function () {
      try {
        const docRef = doc(db, "corpInfo", "ams");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          this.corpInfo = data;
        }
      } catch (error) {
        console.error(error);
      }
    },

    recaptchaFailed() {},

    sendEmail: async function () {
      this.v$.form.$validate();
      if (this.v$.form.$invalid) {
        return false;
      }

      if (this.resRecaptcha == null) {
        this.modal.title = "Error Message";
        this.modal.body = "Recaptcha Invalid";
        this.modal.type = "INFO";
        this.$refs.modal.openCloseFun(true);
        return false;
      }

      this.$emit("submit", this.form);

      let config = {
        headers: {
          "g-recaptcha-response": this.resRecaptcha,
        },
      };

      const that = this;

      this.modal.type = "PROGRESS";
      this.$refs.modal.openCloseFun(true);
      this.axios
        .post(
          process.env.VUE_APP_API_EMAIL,
          {
            to: this.corpInfo.email,
            from: this.form.email,
            subject: `New Question From Web AMS - ${this.form.email}`,
            message: this.form.message,
            name: this.form.name,
            hp: this.form.phoneNumber,
          },
          config
        )
        .then((response) => {
          if (response.status === 202) {
            that.modal.title = "Success Message";
            that.modal.body = "Your Message has been sent";
          } else {
            that.modal.title = "Failed Message";
            that.modal.body = `Your Message fail to sent. Please Contact Our Customer Care ${that.corpInfo.telp}`;
          }
          that.modal.type = "INFO";
        })
        .catch((err) => {
          console.error(err);
          that.modal.title = "Failed Message";
          that.modal.body = `Your Message fail to sent. Please Contact Our Customer Care ${that.corpInfo.telp}`;
          that.modal.type = "INFO";
        });

      try {
        const ref = collection(db, "contact");
        addDoc(ref, this.form);
      } catch (error) {
        console.error("Error sending message to Firestore: ", error);
      } finally {
        this.$refs.modal.openCloseFun(true);
        // Reset form field
        this.form.name = "";
        this.form.email = "";
        this.form.message = "";
        this.form.phoneNumber = "";
        this.recaptchaExpired();
        this.v$.form.$reset();
      }
    },
  },
};
</script>

<style scoped>
.bg-ligth-grey {
  background-color: #d9d9d9;
  width: 100%;
}

@media screen and (max-width: 1199px) {
}

@media screen and (max-width: 777px) {
}
</style>
