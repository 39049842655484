<template>
  <HeaderSite :parentMenu="this.$route.name" />
  <div>
    <div class="container client">
      <div class="row my-5 justify-content-md-center">
        <div
          class="col-md-auto p-3 align-self-center text-center"
          v-for="client in clients"
          :key="client.id"
        >
          <img
            :src="'data:image/png;base64,' + client.image"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>

  <FooterSite />
</template>

<script>
import { db } from "@/main";
import HeaderSite from "@/components/headerSite.vue";
import FooterSite from "@/components/footerSite.vue";
import { collection, getDocs, orderBy, query } from "firebase/firestore";

export default {
  name: "clientPage",
  components: {
    HeaderSite,
    FooterSite,
  },
  data() {
    return {
      clients: [],
    };
  },
  mounted() {
    this.loadFirebase();
  },
  methods: {
    loadFirebase: async function () {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "client"), orderBy("sequence", "asc"))
        );
        const clients = [];
        querySnapshot.forEach((doc) => {
          clients.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        this.clients = clients;
      } catch (error) {
        console.error("Error getting document: ", error);
      }
    },
  },
};
</script>

<style scoped>
.client img {
  transition: transform 0.5s;
}
.client img:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 1199px) {
}

@media screen and (max-width: 777px) {
  .client img {
    max-width: 100%;
  }
}
</style>
