<template>
  <HeaderSite :parentMenu="this.$route.name" />
  <div>
    <div class="container career">
      <div class="row align-items-center justify-content-start my-4">
        <div
          class="col-6 col-sm-4 my-3"
          v-for="(career, index) in careers"
          :key="index"
        >
          <img
            :src="'data:image/png;base64,' + career"
            width="320"
            height="320"
          />
        </div>
      </div>
    </div>
    <div v-if="hideMoreButton" class="text-center">
      <button
        v-if="!disableMoreButton"
        class="btn btn-danger btn-sm m-4"
        @click="next"
      >
        More
      </button>
    </div>
  </div>

  <FooterSite />
</template>

<script>
import { db } from "@/main";
import HeaderSite from "@/components/headerSite.vue";
import FooterSite from "@/components/footerSite.vue";
import {
  collection,
  getDocs,
  query,
  limit,
  startAfter,
  getCountFromServer,
  where,
} from "firebase/firestore";

export default {
  name: "careerPage",
  components: {
    HeaderSite,
    FooterSite,
  },
  data() {
    return {
      careers: [],
      lastVisible: 0,
      documentCount: 0,
      hasMore: true,
    };
  },
  computed: {
    disableMoreButton() {
      return this.documentCount <= 3;
    },
    hideMoreButton() {
      return this.hasMore;
    },
  },
  mounted() {
    this.loadPage();
    this.loadCount();
  },
  methods: {
    loadCount: async function () {
      try {
        const coll = query(
          collection(db, "career"),
          where("active", "==", true)
        );
        const snapshot = await getCountFromServer(coll);
        this.documentCount = snapshot.data().count;
      } catch (error) {
        console.error("Error getting documents: ", error);
      }
    },
    loadPage: async function () {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "career"), where("active", "==", true), limit(3))
        );
        const careers = [];
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        this.lastVisible = lastVisible;
        querySnapshot.forEach((doc) => {
          careers.push(doc.data().image);
        });
        this.careers = careers;
      } catch (error) {
        console.error("Error loading career images: ", error);
      }
    },
    next: async function () {
      try {
        const nextPage = query(
          collection(db, "career"),
          where("active", "==", true),
          startAfter(this.lastVisible),
          limit(3)
        );

        const querySnapshot = await getDocs(nextPage);

        if (querySnapshot.docs.length > 0) {
          const careers = [];
          const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          this.lastVisible = lastVisible;

          querySnapshot.forEach((doc) => {
            careers.push(doc.data().image);
          });

          this.careers = [...this.careers, ...careers];
        } else {
          this.hasMore = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.career img {
  transition: transform 0.5s, 0.6s ease box-shadow;
  box-shadow: 0px 0px 15px #d4d4d4 !important;
}
.career img:hover {
  transform: scale(1.1);
  box-shadow: 18px 18px 20px #d4d4d4 !important;
}

@media screen and (max-width: 1199px) {
}

@media screen and (max-width: 777px) {
  .career img {
    max-width: 100%;
  }
}
</style>
