<template>
  <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid logo">
      <div class="col-md-4">
        <router-link :to="{ name: 'home' }" class="navbar-brand">
          <img :src="'data:image/png;base64,' + logo" width="272" />
        </router-link>
        <div class="tagline">
          {{ tagline }}
        </div>
      </div>

      <div class="side-navbar">
        <ul class="nav ms-auto">
          <li class="nav-item">
            <router-link
              :to="{ name: 'home' }"
              class="nav-link"
              :class="activeLink('home')"
              aria-current="page"
              @click="hideMenu"
              >Home</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'about' }"
              class="nav-link"
              :class="activeLink('about')"
              aria-current="page"
              @click="hideMenu"
              >About</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'products' }"
              class="nav-link"
              :class="activeLink('products')"
              @click="hideMenu"
              >Products</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'client' }"
              class="nav-link"
              :class="activeLink('client')"
              @click="hideMenu"
              >Clients</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'contact' }"
              class="nav-link"
              :class="activeLink('contact')"
              @click="hideMenu"
              >Contact</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'career' }"
              class="nav-link"
              :class="activeLink('career')"
              @click="hideMenu"
              >Careers</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              :to="{ name: 'article' }"
              class="nav-link"
              :class="activeLink('article')"
              @click="hideMenu"
            >
              Articles
            </router-link>
          </li>
        </ul>
      </div>
      <!-- <div class="side-navbar" v-for="menu in menus" :key="menu.id">
        <ul class="nav ms-auto">
          <li class="nav-item">
            <router-link
              :to="{ name: 'dynamic', params: { id: menu.id } }"
              class="nav-link"
              :class="activeLink('dynamic')"
              aria-current="page"
              @click="hideMenu"
            >
              {{ menu.id }}
            </router-link>
          </li>
        </ul>
      </div> -->
      <span class="material-icons" v-on:click="toggleMenu"> menu </span>
    </div>
    <div class="shadow-banner"></div>
  </nav>

  <div class="image-banner">
    <div>
      <iframe
        v-if="$route.name === 'contact'"
        class="mapOffice"
        :src="mapBanner"
        allowfullscreen=""
        width="100%"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <div class="container-fluid d-flex" :v-if="$route.name != 'contact'">
      <div class="row align-items-center">
        <div class="col col-sm-12 px-5 py-4">
          <div class="col col-sm-8">
            <h1>{{ titleBanner }}</h1>
            <h6>
              <b>{{ descBanner }}</b>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/main";
import { doc, collection, getDoc, getDocs, query } from "firebase/firestore";

export default {
  name: "headerSite",
  props: {
    activeMenu: { type: String, default: "home" },
    parentMenu: String,
  },
  data: function () {
    return {
      isShowMenu: true,
      menus: [],
      mainBanner: {},
      titleBanner: "",
      descBanner: "",
      mapBanner: "",
      logo: "",
      tagline: "",
    };
  },
  mounted() {
    this.loadHeader();
    this.loadMenu();
    this.loadLogo();
  },
  methods: {
    loadLogo: async function () {
      try {
        const getHeader = doc(db, "corpInfo", "ams");
        const docHeader = await getDoc(getHeader);
        if (docHeader.exists()) {
          const data = docHeader.data();
          this.logo = data.logo;
          this.tagline = data.tagline;
        }
      } catch (error) {
        console.error("error");
      }
    },
    loadMenu: async function () {
      try {
        const getMenu = await getDocs(query(collection(db, "customPage")));
        const menu = [];
        getMenu.forEach((doc) => {
          menu.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        this.menus = menu;
      } catch (error) {
        console.error("error loading menu: ", error);
      }
    },
    activeLink: function (menuname) {
      return this.parentMenu === menuname ? "link-danger" : "link-dark";
    },
    toggleMenu: function () {
      if (this.isShowMenu) {
        document
          .getElementsByClassName("side-navbar")[0]
          .classList.add("side-navbar-active");
      } else {
        document
          .getElementsByClassName("side-navbar")[0]
          .classList.remove("side-navbar-active");
      }
      this.isShowMenu = !this.isShowMenu;
    },
    hideMenu: function () {
      this.isShowMenu = !this.isShowMenu;
      document
        .getElementsByClassName("side-navbar")[0]
        .classList.remove("side-navbar-active");
    },
    loadHeader: function () {
      this.axios
        .get(process.env.VUE_APP_SUBPATH + "data-sample/header.json")
        .then((response) => {
          this.mainBanner = response.data.mainBanner;

          this.titleBanner = this.mainBanner[this.$route.name].title;
          this.descBanner = this.mainBanner[this.$route.name].desc;

          if (this.$route.name != "contact") {
            const bannerImg = require("@/assets/img/" +
              this.mainBanner[this.$route.name].image);
            document.getElementsByClassName(
              "image-banner"
            )[0].style.backgroundImage = `url(${bannerImg})`;
          } else {
            this.mapBanner = this.mainBanner[this.$route.name].map;
          }
        });
    },
  },
};
</script>

<style scoped>
@import "../assets/css/header.css";
@import "../assets/css/global.css";
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Raleway+Dots);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
</style>
