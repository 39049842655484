<template>
    <HeaderSite :parentMenu="'products'" />
    
    <div>
        <div class="container">
            <div class="row">
                {{product.content}}
            </div>
        </div>
    </div>
    <div class="my-4 mx-3">
        <router-link :to="{ name: 'products'}" role="button" class="btn btn-secondary">&lt; Back</router-link>
    </div>
    <FooterSite />
    
</template>

<script>
import HeaderSite from '@/components/headerSite.vue';
import FooterSite from '@/components/footerSite.vue';

export default {
    name : 'productDetailPage',
    components : {
        HeaderSite,
        FooterSite
    },
    data(){
        return{
            product : {}
        }
    },
    mounted() {
        this.loadProductDetail();
    },
    methods : {
        loadProductDetail : function(){

            this.axios
            .get(process.env.VUE_APP_SUBPATH+'data-sample/prods.json')
            .then(response => {
                const sampleId = this.$route.params.id;
                const articleDetail = response.data.items.filter((obj)=>{
                    return obj.id == sampleId;
                }).pop();
                this.product = articleDetail;
            })

        }
    }
}
</script>

<style scoped>

.card{
    transition: .6s ease box-shadow;
}
.card:hover{
    box-shadow: 0 0px 15px rgb(86, 175, 248) !important;
    cursor: pointer;
}

.paging div{
    transition: .6s ease;
    border-radius: 3em;
}

.paging div:hover{
    background-color: #efefef;
    cursor: pointer;
}

</style>