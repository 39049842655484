import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import VueGtag from "vue-gtag";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/js/bootstrap.min";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

initializeApp(firebaseConfig);
const db = getFirestore();
export { db };

createApp(App)
  .use(router)
  .use(VueAxios, axios)
  .use(
    VueGtag,
    {
      appName: "AMS-WEB-COMPRO",
      config: {
        id: process.env.VUE_APP_MEASUREMENT_ID,
        params: {
          anonymize_ip: true,
        },
      },
    },
    router
  )
  .mount("#app");
