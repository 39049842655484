<template>
  <HeaderSite :parentMenu="this.$route.name" />
  <div class="">
    <div class="container">
      <div class="row py-5 justify-content-md-center">
        <div class="col-md-4 text-center illus">
          <img src="@/assets/img/illus3.jpg" class="col-md-12" />
        </div>
        <div class="col-md-6 p-4 align-self-center service">
          <h3>Company History</h3>
          <br />
          <div v-html="history" class="text-justify"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="visimisi">
    <div class="container-fluid p-5">
      <div class="row justify-content-md-center">
        <div class="col-md-4 my-3 p-5 bg">
          <h3>Vision</h3>
          <div v-html="vision" class="text-justify"></div>
          <br />
        </div>
        <div class="col-md-4 my-3 p-5 bg">
          <h3>Mission</h3>
          <div v-html="mission" class="text-justify"></div>
        </div>
      </div>
    </div>
  </div>
  <!--
    <div class="team bg-ligth-grey">
        <div class="container ">
            <div class="row justify-content-md-center">
                <div class="col-md-5 text-center">
                    <div class="my-5"></div>
                    <h2>Our Team</h2>
                    <div class="my-3"></div>
                </div>
            </div>
            <div class="row justify-content-md-center">
                <div class="col-sm-3 d-flex justify-content-center my-3 team-ing-frame" v-for="team in teams" :key="team.id">
                    <div class="teamname">{{team.name}}</div>
                    <img :src="require(`@/assets/img/${team.image}`)" />
                </div>
                <div class="my-3"></div>
            </div>
        </div>
    </div>
    -->
  <FooterSite />
</template>

<script scoped>
import { db } from "@/main";
import HeaderSite from "@/components/headerSite.vue";
import FooterSite from "@/components/footerSite.vue";
import { getDoc, doc } from "firebase/firestore";

export default {
  name: "aboutPage",
  components: {
    HeaderSite,
    FooterSite,
  },
  data() {
    return {
      history: "",
      vision: "",
      mission: "",
      teams: [],
    };
  },
  mounted() {
    this.loadTeam();
    this.loadAboutFirebase();
  },
  methods: {
    loadAboutFirebase: async function () {
      try {
        const docRef = doc(db, "text", "aboutPage");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          this.history = data.history;
          this.vision = data.vision;
          this.mission = data.mission;
        }
      } catch (error) {
        console.error(error);
      }
    },

    loadTeam: function () {
      this.axios.get("data-sample/team.json").then((response) => {
        this.teams = response.data;
      });
    },
  },
};
</script>

<style scoped>
.illus img {
  border: 3px solid #fff;
  border-radius: 10px;
  box-shadow: 0 0px 10px rgb(107, 107, 107);
}

.visimisi {
  background-image: url(@/assets/img/visimisi2.jpg);
  background-position: center;
  background-size: cover;
}

.visimisi .bg {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  margin: 0 1em;
}

.visimisi h3 {
  margin-bottom: 1.5em;
}

.visimisi .bg ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  gap: 1rem;
}

.visimisi .bg li {
  display: grid;
  grid-template-columns: 0 1fr;
  gap: 1.75em;
  align-items: start;
}

.visimisi .bg li::before {
  content: attr(data-icon);
}

.team img {
  border-radius: 10px;
  transition: 0.6s ease border, 0.6s ease box-shadow;
}

.team .teamname {
  position: absolute;
  width: 220px;
  margin-top: 200px;
  margin-left: -50px;
  padding: 15px 0px 15px 30px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 13pt;
  font-style: italic;
  font-weight: bold;
}

.team img:hover {
  box-shadow: 0 0px 10px rgb(86, 175, 248) !important;
  border-radius: 10px;
}

.team-ing-frame {
  margin: 0 1em;
}

@media screen and (max-width: 1199px) {
  .illus {
    display: none;
  }
  .service {
    text-align: center;
  }
  .team-ing-frame,
  .visimisi .bg {
    margin: 0;
  }
}

@media screen and (max-width: 777px) {
  .prod .material-icons {
    margin-top: 0.8em;
  }
}
</style>
