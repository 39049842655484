<template>
    <div >
        <!-- Modal -->
        <div class="modal fade " tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog" >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" v-if="modal.type === 'INFO'">
                    <div class="modal-header">
                        <h5 class="modal-title">{{modal.title}}</h5>
                        <button type="button" @click="openCloseFun(false)" class="btn-close" ></button>
                    </div>
                    <div class="modal-body">
                        <p>{{modal.body}}</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button"  @click="openCloseFun(false)" class="btn btn-secondary">Close</button>
                    </div>
                </div>
                <div class="modal-content" v-if="modal.type === 'PROGRESS'">
                    <div class="progress">
                        <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    name : 'modalSite',
    props: {
      modal: {
        title : String,
        body : String,
      },
    },
    data : function(){
        return {
        }
    },
    methods : {
        openCloseFun : function(visible){
            if(visible){
                document.getElementsByClassName('modal')[0].classList.add('show')
                document.getElementsByClassName('modal')[0].classList.add('bg-modal')
            }else{
                document.getElementsByClassName('modal')[0].classList.remove('show')
                document.getElementsByClassName('modal')[0].classList.remove('bg-modal')
            }
        },
       
    }
}
</script>
<style scoped>
    .bg-modal{
        display: block
    }
    .modal {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }
</style>