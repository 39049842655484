<template>
  <HeaderSite :parentMenu="this.$route.name" />
  <div>
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-md-7 text-center">
          <div class="my-5"></div>
          <h2>Our Products</h2>
          <div class="py-3">
            {{ intro }}
          </div>
        </div>
      </div>
      <div class="row justify-content-center prod">
        <div
          class="col-sm-3 d-flex justify-content-center m-2 mx-3 btn-div"
          v-for="prod in productList"
          :key="prod.id"
        >
          <div class="title">
            <h3>{{ prod.name }}</h3>
          </div>
          <img
            :src="'data:image/png;base64,' + prod.image"
            width="300"
            height="200"
            @mouseover="showProdTitle($event)"
            @mouseout="hideProdTitle($event)"
          />
        </div>
      </div>
      <div class="my-5 d-flex justify-content-center">
        <button class="btn btn-danger btn-sm m-4 ">
          <router-link class="text-decoration-none text-white" :to="{ name: 'products' }"
            >See More
          </router-link>
        </button>
      </div>
    </div>
  </div>
  <!-- <div class="bg-ligth-grey">
        <div class="container">
            <div class="row justify-content-md-center">
                <div class="col-md-5 text-center">
                    <div class="my-5"></div>
                    <h2>What Our Clients Say</h2>
                    <div class="my-5"></div>
                </div>
            </div>
            <div class="row justify-content-md-center">
                <div class="col-md-4 my-3" v-for="testi in itemTestimoni" :key="testi.id">
                    <div class="card">
                        <div class="card-body">
                            <div class="row my-3 g-0">
                                <div class="col-2 px-0 text-end">
                                    <img :src="require(`@/assets/img/${testi.icon}`)" />
                                </div>
                                <div class="col-1"></div>
                                <div class="col-9 px-0">
                                    <span class="fw-bolder">{{testi.name}}</span> <br>
                                    <span><small style="color:#6975b1">{{testi.title}}</small></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    {{testi.desc.substring(0, 134)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-md-center">
                <div class="col text-center my-3">
                </div>
            </div>
        </div>
    </div> -->
  <div class="joinus">
    <div class="container-fluid p-5">
      <div class="row d-flex justify-content-md-end align-items-center my-5">
        <div class="col-md-4 text-end text-white">
          <h2>Join Us</h2>
          <h5>
            Ready to take your career to the next level? Join our passionate
            team and unlock your full potential
          </h5>
        </div>
      </div>
      <div class="row d-flex justify-content-md-end align-items-center">
        <div class="col-md-2 text-center d-grid">
          <button
            type="button"
            class="btn btn-light btn-sm"
            @click="$router.push({ name: 'career' })"
          >
            Let's Go
          </button>
        </div>
      </div>
    </div>
  </div>
  <FooterSite />
</template>

<script>
import HeaderSite from "@/components/headerSite.vue";
import FooterSite from "@/components/footerSite.vue";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import { db } from "@/main";

export default {
  name: "homePage",
  components: {
    HeaderSite,
    FooterSite,
  },
  data() {
    return {
      intro: "",
      productList: [],
      itemServices: [],
      itemTestimoni: [],
    };
  },
  mounted() {
    // this.loadHome();
    this.loadFirebase();
  },
  methods: {
    loadHome: function () {
      this.axios
        .get(process.env.VUE_APP_SUBPATH + "data-sample/home.json")
        .then((response) => {
          this.itemServices = response.data.itemServices;
          this.itemProducts = response.data.itemProducts;
          this.itemTestimoni = response.data.itemTestimoni;
        });
    },

    loadFirebase: async function () {
      try {
        const docRef = doc(db, "text", "aboutPage");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          this.intro = data.intro;
        }

        const listOfProductsSnapshot = await getDocs(
          query(
            collection(db, "product"),
            orderBy("createdAt", "asc"),
            limit(6)
          )
        );
        listOfProductsSnapshot.forEach((doc) => {
          this.productList.push({
            ...doc.data(),
          });
        });
      } catch (error) {
        console.error(error);
      }
    },

    showProdTitle(e) {
      e.target.parentElement
        .getElementsByClassName("title")[0]
        .classList.add("title-show");
    },

    hideProdTitle(e) {
      e.target.parentElement
        .getElementsByClassName("title")[0]
        .classList.remove("title-show");
    },

    redirectToProduct() {
      this.$router.push({ name: "products" });
    },
  },
};
</script>

<style scoped>
.illus {
  align-items: center;
  display: flex;
}

.illus img {
  border: 3px solid #fff;
  border-radius: 10px;
  box-shadow: 0 0px 10px rgb(107, 107, 107);
}

.service-home .bg {
  background-color: #fff;
  border-radius: 10px;
  margin: 0 1em;
  box-shadow: 0 0px 10px rgb(107, 107, 107);
  transition: 0.6s ease box-shadow;
}

.service-home .bg:hover {
  box-shadow: 0 0px 15px rgb(86, 175, 248) !important;
}

.service-home hr {
  border: none;
  height: 3px !important;
  /* Set the hr color */
  color: #333; /* old IE */
  background-color: #333; /* Modern Browsers */
}

.prod img,
.prod button {
  background-color: #d9d9d9;
  border-radius: 10px;
  box-shadow: 0 0px 10px rgb(156, 156, 156);
  transition: 0.6s ease border, 0.6s ease box-shadow;
}

.prod .material-icons {
  font-size: 7.85em !important;
  color: #bbb;
}

.prod button {
  color: #efefef;
  border: none;
}

.title {
  position: absolute;
  width: 300px;
  height: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: right;
  font-weight: bolder;
  color: #fff;
  font-style: italic;
  background: linear-gradient(
    180deg,
    rgb(97, 97, 97) 0%,
    rgba(150, 150, 150, 0) 100%
  );
  transition: 0.6s ease;
  overflow: hidden;
}

.title h3 {
  position: absolute;
  width: 100%;
  padding-right: 20px;
}
.title-show {
  height: 80px;
  padding-top: 20px;
}
.prod img:hover {
  box-shadow: 0 0px 15px #dc3545 !important;
  border-radius: 10px;
}

.btn-secondary:hover {
  background-color: rgb(173, 173, 173) !important;
}

.btn-div:hover {
  cursor: pointer;
}

.card {
  border-radius: 10px !important;
  min-height: 17em !important;
  min-width: 50% !important;
}
.card-body {
  border-radius: 10px !important;
  box-shadow: 0 0px 5px rgb(107, 107, 107);
  transition: 1s ease box-shadow;
}
.card-body:hover {
  box-shadow: 0 0px 15px #523b3d !important;
}
.card-body img {
  height: 60px;
}

.joinus {
  background-image: url(@/assets/img/joinus.jpg);
  background-position: left;
  background-size: cover;
  min-height: 47vh;
}

@media screen and (max-width: 1199px) {
  .illus {
    display: none;
  }
  .service {
    width: 100% !important;
  }
  .service-home .bg {
    margin: 0px;
  }
  .title {
    height: 80px;
    padding-top: 20px;
  }
}
</style>
